/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap-v5";
import { StepperComponent } from "../../../../../_start/assets/ts/components";
import { KTSVG } from "../../../../../_start/helpers";
import { UserModel2 } from "../models/UserModel2";
import { delUser, tokenVitality } from "../../../../modules/auth/redux/AuthCRUD";
import * as auth from "../../../../modules/auth/redux/AuthRedux";
import { RootState } from "../../../../../setup";


type Props = {
  userData: UserModel2;
  show: boolean;
  handleClose: () => void;
};



const DeleteUserModal: React.FC<Props> = ({ userData, show, handleClose }) => {
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [statusComplete, setStatusComplete] = useState("");
  const [completed, setCompleted] = useState(false)
  const [isVitality, setIsVitality] = useState(true);


  const sessionTokenJWT = useSelector<RootState>(
    ({ auth }) => {
      if (auth.user) {
        tokenVitality(auth.user.token)
          .then(() => {
            console.log("vita OK")
            setIsVitality(true);
          })
          .catch(() => {
            console.log("vita KO")
            setIsVitality(false);
          });

        return JSON.parse(JSON.stringify(auth.user)).token
      }
    }
  ) as string;


  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const prevStep = () => {
    if (!isVitality) {
      dispatch(auth.actions.logout())
    }

    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();
  };

  const nextStep = () => {
    if (!isVitality) {
      dispatch(auth.actions.logout())
    }

    if (!stepper.current) {
      return;
    }

    stepper.current.goNext();
  };

  const endProcess = () => {
    window.location.reload();
  }

  const submit = () => {
    if (!stepper.current) {
      return;
    }

    setLoading(true);

    setTimeout(() => {
      if (sessionTokenJWT) {
        console.log("debug delUser:  " + userData.user.email)
        delUser(userData.user, sessionTokenJWT)
          .then(() => {
            //dispatch(auth.actions.addUser(user));
            setStatusComplete("user succesfully deleted");
            setLoading(false);
            setCompleted(true);
          })
          .catch(() => {
            setLoading(false);
            console.log("debug catched")
            setStatus("Error deleting user");
            setCompleted(true);
          });
      } else {
        dispatch(auth.actions.logout());
      }
    }, 1000);

  };

  const statusMessage = () => {
    if (status) {
      return (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{status}</div>
        </div>
      )
    } else if (statusComplete) {
      return (
        <div className="mb-lg-15 alert alert-info">
          <div className="alert-text font-weight-bold">{statusComplete}</div>
        </div>
      )
    }
  }

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog-centered mw-1000px h-auto"
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
    >
      <div className="container px-10 py-10">
        <div className="modal-header py-2 d-flex justify-content-end border-0">
          {/* begin::Close */}
          <div
            className="btn btn-icon btn-sm btn-light-primary"
            onClick={handleClose}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotone/Navigation/Close.svg"
            />
          </div>
          {/* end::Close */}
        </div>

        <div className="modal-body">
          {/*begin::Stepper */}
          <div
            ref={stepperRef}
            className="stepper stepper-1 d-flex flex-column flex-xl-row flex-row-fluid"
            id="kt_modal_create_app_stepper"
          >
            {/*begin::Aside */}
            <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
              {/*begin::Nav */}
              <div className="stepper-nav d-flex flex-column pt-5">
                {/*begin::Step 1 */}
                <div
                  className="stepper-item current"
                  data-kt-stepper-element="nav"
                >
                  <div className="stepper-wrapper">
                    <div className="stepper-icon">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">1</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title">Delete user</h3>
                      <div className="stepper-desc">Data</div>
                    </div>
                  </div>
                </div>
                {/*end::Step 1 */}

                {/*begin::Step 2 */}
                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-wrapper">
                    <div className="stepper-icon">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">2</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title">Summary</h3>
                      <div className="stepper-desc">
                        New user
                      </div>
                    </div>
                  </div>
                </div>
                {/*end::Step 2 */}


              </div>
              {/*end::Nav */}
            </div>
            {/*begin::Aside */}





            {/*begin::Content */}
            <div className="d-flex flex-row-fluid justify-content-center">
              {/*begin::Form */}
              <form
                className="pb-5 w-100 w-md-400px w-xl-500px"
                noValidate
                id="kt_modal_create_app_form"
                onSubmit={submit}
              >


                {/*begin::Step 1 */}
                <div className="pb-5 current" data-kt-stepper-element="content">
                  <div className="w-100">
                    {/*begin::Heading */}
                    <div className="pb-5 pb-lg-10">
                      <h3 className="fw-bolder text-dark display-6">
                        Delete user
                      </h3>
                    </div>
                    {/*begin::Heading */}

                    {/*begin::Form Group */}
                    <div className="fv-row mb-12">
                      <label className="fs-6 fw-bolder text-dark form-label">
                        E-Mail
                      </label>
                      <input
                        type="email"
                        className="form-control form-control-lg form-control-solid"
                        name="email"
                        placeholder=""
                        value={userData.user.email}
                        readOnly={true}
                      />

                      <label className="fs-6 fw-bolder text-dark form-label">
                        Display name
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        name="email"
                        placeholder=""
                        value={userData.user.displayName}
                        readOnly={true}
                      />


                    </div>
                    {/*end::Form Group */}
                  </div>
                </div>
                {/*end::Step 1 */}



                {/*begin::Step 2 */}
                <div className="pb-5" data-kt-stepper-element="content">
                  <div className="w-100">
                    {/*begin::Heading */}
                    <div className="pb-5 pb-lg-10">
                      <h3 className="fw-bolder text-dark display-6">
                        Summary
                      </h3>
                    </div>
                    {/*begin::Heading */}

                    {/*begin::Form Group */}
                    <div className="fv-row mb-12">

                      <label className="fs-6 fw-bolder text-dark form-label">
                        E-Mail
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        name="email"
                        placeholder=""
                        value={userData.user.email}
                        readOnly
                      />

                      <label className="fs-6 fw-bolder text-dark form-label">
                        Display name
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        name="displayName"
                        placeholder=""
                        value={userData.user.displayName}
                        readOnly
                      />

                    </div>
                    {/*end::Form Group */}

                    {statusMessage()}

                  </div>
                </div>
                {/*end::Step 2 */}


                {/*begin::Actions */}
                <div className="d-flex justify-content-between pt-10">
                  <div className="mr-2">
                    <button
                      type="button"
                      className="btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3"
                      data-kt-stepper-action="previous"
                      onClick={prevStep}
                    >
                      <KTSVG
                        path="/media/icons/duotone/Navigation/Left-2.svg"
                        className="svg-icon-3 me-1"
                      />{" "}
                      Previous
                    </button>
                  </div>
                  <div>
                    {loading && (
                      <span
                        className="spinner-border text-primary ms-3 mt-6"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </span>
                    )}

                    {!completed ? (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3"
                        data-kt-stepper-action="submit"
                        onClick={submit}
                      >
                        Submit{" "}
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Right-2.svg"
                          className="svg-icon-3 ms-2"
                        />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3"
                        onClick={endProcess}
                      >
                        End{" "}
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Right-2.svg"
                          className="svg-icon-3 ms-2"
                        />
                      </button>
                    )
                    }

                    <button
                      type="submit"
                      //id="kt_login_signin_form_submit_button"
                      className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-3"
                      data-kt-stepper-action="next"
                      onClick={nextStep}
                    >
                      {!loading && <span className="indicator-label">Next Step{" "}
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Right-2.svg"
                          className="svg-icon-3 ms-1"
                        />
                      </span>}
                      {loading && (
                        <span className="indicator-progress" style={{ display: "block" }}>
                          Please wait...{" "}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>

                  </div>
                </div>
                {/*end::Actions */}


              </form>
              {/*end::Form */}
            </div>
            {/*end::Content */}
          </div>
          {/* end::Stepper */}
        </div>
      </div>
    </Modal >
  );
};

export { DeleteUserModal };
