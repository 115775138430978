/* eslint-disable jsx-a11y/anchor-is-valid */
//import React from "react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { KTSVG, formatDate } from "../../../../../_start/helpers";
import { tokenVitality, getLicenses } from "../redux/LicCRUD";
import * as auth from "../../../../../app/modules/auth/redux/AuthRedux";
import { RootState } from "../../../../../setup";
import { UpdateLicModal } from "./UpdateLicModal";
import { DeleteLicModal } from "./DeleteLicModal";
import { defaultCreateLicData, ICreateLicData } from "../models/ILicModels";


type Props = {
  className: string;
  innerPadding?: string;
  color?: string;
};


const TablesLicenseWidget: React.FC<Props> = ({
  className,
  innerPadding = "",
  color = "primary",
  children,
}) => {
  const dispatch = useDispatch();
  const [isVitality, setIsVitality] = useState(true);
  const [licenses, setLicenses] = useState([]);
  const [selectedLicense, setSelectedLicense] = useState<ICreateLicData>(defaultCreateLicData);
  const [showCreateLicModal, setShowCreateLicModal] = useState(false);
  const [showDeleteLicModal, setShowDeleteLicModal] = useState(false);

  const userType = useSelector<RootState>(
    ({ auth }) => {
      var retValue = "Customer";
      if (auth.user) {
        auth.user.user.groups.forEach(element => {
          if (element === "admin")
            retValue = "Admin";
        });
        return retValue;
      }
    }
  ) as string;

  const sessionTokenJWT = useSelector<RootState>(
    ({ auth }) => {
      if (auth.user) {
        tokenVitality(auth.user.token)
          .then(() => {
            console.log("tablesLicense vita OK")
            setIsVitality(true);
          })
          .catch(() => {
            console.log("tablesLicense vita KO")
            setIsVitality(false);
          });

        return JSON.parse(JSON.stringify(auth.user)).token
      }
    }
  ) as string;

  useEffect(() => {
    setTimeout(() => {
      if (sessionTokenJWT) {
        getLicenses(sessionTokenJWT)
          .then(({ data: licenses }) => {
            //dispatch(lic.actions.getLicenses());
            setLicenses(licenses)
            //console.log(JSON.stringify(licenses))
          })
          .catch(() => {
            console.log("debug catched")
          });
      } else {
        dispatch(auth.actions.logout());
      }
    }, 1000);

  }, [dispatch, sessionTokenJWT]);

  const handleClickLicense = (e: Event) => {
    if (!isVitality) {
      dispatch(auth.actions.logout())
    }

    setSelectedLicense(JSON.parse(JSON.stringify(e)));
    setShowCreateLicModal(true)
  }

  const handleDeleteLicense = (e: Event) => {
    if (!isVitality) {
      dispatch(auth.actions.logout())
    }

    setSelectedLicense(JSON.parse(JSON.stringify(e)));
    setShowDeleteLicModal(true)
  }

  const myHeader = () => {
    var adminHdr = null;
    if (userType === 'Admin') {
      adminHdr = (<td className="ps-0 py-5">Product Key</td>)
    }

    var hdr = (
      <tr className="text-start text-muted fw-bolder text-gray-400 text-uppercase fs-7 border-gray-100 border-bottom-1">
        <td className="ps-0 py-5">Identifier</td>
        <td className="ps-0  py-5">Organization</td>
        <td className="ps-0  py-5">Product</td>
        <td className="ps-0 py-5">Version</td>
        {adminHdr}
        <td className="ps-0  py-5">Start Date</td>
        <td className="ps-0  py-5">End Date</td>
        <td className="ps-0  py-5">Number of Users</td>
        <td className="ps-0  py-5">Active Sessions</td>
        <td className="pe-0  text-end py-5">Action</td>
      </tr>)

    return hdr;
  }


  const myRows = () => (
    licenses.map((elem, index) => {
      var jsonElem = JSON.parse(JSON.stringify(elem))

      const displayProductKey = () => {
        if (userType === 'Admin') {
          return (<td>
            <span className={`text-${color} fw-bolder text-wrap d-block fs-6 max-w-90px`}
              style={{ width: 120, backgroundColor: "rgba(0, 0, 0, 0)" }} >
              {jsonElem.licenseData.productKey}
            </span>
          </td>)
        } else {
          return (<td></td>)
        }
      }

      const myIconActions = () => {
        var icon = null;

        if (userType === 'Admin') {
          icon = (
            <td className="pe-0 text-end" style={{ width: 100 }}>
              <a
                href="#" onClick={(e) => { handleClickLicense(jsonElem) }}
                className="btn btn-icon btn-bg-light  btn-color-muted btn-active-color-primary btn-sm mx-3"
              >
                <KTSVG
                  className="svg-icon-4"
                  path="/media/icons/duotone/Communication/Write.svg"
                />
              </a>
              <a
                href="#" onClick={(e) => { handleDeleteLicense(jsonElem) }}
                className="btn btn-icon btn-bg-light  btn-color-muted btn-active-color-primary btn-sm"
              >
                <KTSVG
                  className="svg-icon-4"
                  path="/media/icons/duotone/General/Trash.svg"
                />
              </a>
            </td>
          )
        } else {
          icon = (
            <td className="pe-0 text-end" style={{ width: 100 }}>
              <a
                href="#" onClick={(e) => { handleClickLicense(jsonElem) }}
                className="btn btn-icon btn-bg-light  btn-color-muted btn-active-color-primary btn-sm mx-3"
              >
                <KTSVG
                  className="svg-icon-4"
                  path="/media/icons/duotone/Communication/Write.svg"
                />
              </a>
            </td>
          )
        }

        return icon;
      }




      if (jsonElem.licenseData) {
        return (
          <tr key={index}>
            <td >
              <span className={`text-${color} fw-bolder d-block fs-6`} >
                {jsonElem.idLicense}
              </span>
            </td>
            <td>
              <span className={`text-${color} fw-bolder text-wrap d-block fs-6`}
                style={{ width: 120, backgroundColor: "rgba(0, 0, 0, 0)" }} >
                {jsonElem.licenseData.organizzazione}
              </span>
            </td>
            <td>
              <span className={`text-${color} fw-bolder d-block fs-6`}>
                {jsonElem.licenseData.prodotto}
              </span>
            </td>
            <td>
              <span className={`text-${color} fw-bolder d-block fs-6`}>
                {jsonElem.licenseData.versione}
              </span>
            </td>
            {displayProductKey()}
            <td>
              <span className={`text-${color} fw-bolder d-block fs-6`}>
                {formatDate(jsonElem.licenseData.dataInizio)}
              </span>
            </td>
            <td>
              <span className={`text-${color} fw-bolder d-block fs-6`}>
                {formatDate(jsonElem.licenseData.dataFine)}
              </span>
            </td>
            <td>
              <span className={`text-${color} fw-bolder d-block fs-6`} style={{ width: 25 }}>
                {jsonElem.licenseData.numUtenti}
              </span>
            </td>
            <td>
              <span className={`text-${color} fw-bolder d-block fs-6`} style={{ width: 25 }}>
                {jsonElem.currentSessions.length}
              </span>
            </td>
            {myIconActions()}
          </tr>
        )
      } else {
        return null;
      }
    })
  )


  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-dark">License Management</span>
            <span className="text-muted mt-3 fw-bold fs-7">
              Released Licenses
            </span>
          </h3>
        </div>
        {/* end::Header*/}

        {/* begin::Body*/}
        <div className="card-body py-0">
          {/* begin::Table*/}
          <div className="table-responsive">
            <table
              className="table align-middle border-gray-100"
              id="kt_advance_table_widget_4"
            >
              <thead>
                {myHeader()}
              </thead>
              <tbody>
                {myRows()}
              </tbody>
            </table>
          </div>
          {/* end::Table*/}
        </div>
        {/* end::Body*/}
      </div>

      <UpdateLicModal
        licData={selectedLicense}
        show={showCreateLicModal}
        handleClose={() => setShowCreateLicModal(false)}
      />

      <DeleteLicModal
        licData={selectedLicense}
        show={showDeleteLicModal}
        handleClose={() => setShowDeleteLicModal(false)}
      />

    </>
  );
};

export { TablesLicenseWidget };
