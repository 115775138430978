import React from "react";
import { AsideMenuItem } from "./AsideMenuItem";

import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";

export function AsideMenuMain() {
  const userType = useSelector<RootState>(
    ({ auth }) => {
      var retValue = "Customer";
      if (auth.user) {
        auth.user.user.groups.forEach(element => {
          if (element === "admin")
            retValue = "Admin";
        });
        return retValue;
      }
    }
  ) as string;


  const itemNewUser = () => {
    var myRet
    if (userType === 'Admin') {
      myRet = (<AsideMenuItem to="/manuser" title="User management" />)
    }

    return myRet;
  }

  return (
    <>
      {" "}
      <>
        <>
          <div className="menu-item">
            <h4 className="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">
              Dashboards
            </h4>
          </div>
          <AsideMenuItem to="/manlic" title="License management" />
          {itemNewUser()}
        </>


        <>
          <div className="menu-item mt-10">
            <h4 className="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">
              Resources
            </h4>
          </div>
          <AsideMenuItem to="/docs" title="Documentation" />
        </>
      </>
    </>
  );
}
