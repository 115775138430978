export interface ILicBasic {
  organizzazione: string;
  email: string,
  prodotto: string;
  versione: string;
  terminiLicenza: string;
  dataInizio: string;
  dataFine: string;
  numUtenti: number;
}

export interface ICreateLicData {
  licenseData: ILicBasic;
}

export const defaultCreateLicData: ICreateLicData = {
  licenseData: {
    organizzazione: "",
    email: "",
    prodotto: "HY-STONE",
    versione: "1.0",
    terminiLicenza: "This License Agreement is entered into by and between EG4 Risk s.r.l. and the user of the HY-STONE software product.\n\n" +
      "1. License: The User hereby retains the right to use the Program by accepting this License Agreement. The User shall utilize the Program for in-house usage only and install each license on a single designated computer station unless EG4 specifically authorizes the User to utilize the License on the basis of multi-station usage. The User shall not revise, alter, translate, copy, sell, lease, publish or otherwise distribute the Program, documentation, printed materials, the License and/or any part thereof without prior express written consent of EG4.\n\n" +
      "2. Use of License: No Program shall be provided for use by the User by virtue of this License Agreement alone. This License Agreement shall become validated upon the issuance of a purchase order by the User and the acceptance of the purchase order by EG4, and the full payment of the agreed price has been remitted to EG4 or its authorized representative, unless EG4 specifically permits the use of the Program otherwise.\n\n" +
      "3. License Transfer: The User shall not transfer or assign the Program, security device or License to any other person without prior express written consent of EG4. Moreover, the User shall be responsible for safeguarding the security device.\n\n" +
      "4. Copyright: EG4 shall have and retain the ownership of the Program and all associated documents, which are copyrighted and protected by the governing copyright laws. The User may copy the Program and print the related documents for internal use. The User shall not alter, copy, translate, issue, publish, distribute, assign or transfer the original or duplicate the Program or related documents either physically or electronically for other purposes.\n\n" +
      "5. Responsibility: EG4 makes no warranty or representation in connection with the Program, services and the related materials, express or implied, by operation of law or otherwise, including any implied warranty of merchantability or fitness for a particular purpose. EG4 and any other affiliated companies shall not be held liable for loss of profit, loss of business, or other financial loss which may be caused directly or indirectly by the inadequacy of the Program for any purpose or use thereof or by a defect or deficiency therein. Accordingly, the User is encouraged to fully understand the bases of the Program and become familiar with the Users Manuals. The User shall also independently verify the results produced by the Program.\n\n" +
      "6. Warranty: EG4 provides the warranty contained in this Clause 6 only to the extent of the physical fitness of the Program, which consists of the executable software, security device and manuals in an electronic format. This is the only warranty that EG4 provides pursuant to this License Agreement. EG4 shall replace the Program, which exhibits manufacturing defects with one in proper working order within the first year of the purchase of the License for free of charge. The Program replacement shall not apply to damages caused as a result of the User’s carelessness, ill treatment or misuse of the Program.\n\n" +
      "7. Term: The User may terminate the License Agreement by returning the Program including the security device and purging all copies thereof. EG4 may also terminate the License Agreement in the event of default on the terms and conditions of this License Agreement by the User.\n\n" +
      "8. Governing Law: This License Agreement will be governed by and construed in accordance with the substantive laws in force in Italy.\n\n" +
      "9. Acknowledgement: The User acknowledges that he/she has read and understood the entire contents of this License Agreement and hereby agrees to the terms and conditions as set forth herein. Also, this Agreement and the terms and conditions herein supersede and replace all prior proposals and agreements.\n\n",
    dataInizio: "",
    dataFine: "",
    numUtenti: 1,
  },
};