import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { RootState } from "../setup";
import { ThemeProvider } from "../_start/layout/core";
import { MasterLayout } from "../_start/layout/MasterLayout";
import { Logout } from "./modules/auth/Logout";
import { PrivateRoutes } from "./routing/PrivateRoutes";
import { PublicRoutes } from "./routing/PublicRoutes";
import { tokenVitality } from "./modules/auth/redux/AuthCRUD";

type Props = {
  basename: string;
};

const App: React.FC<Props> = ({ basename }) => {

  const [isVitality, setIsVitality] = useState(true);

  const isAuthorized = useSelector<RootState>(
    //({ auth }) => auth.user,
    ({ auth }) => {
      return auth.user
    },
    shallowEqual
  );

  const sessionTokenJWT = useSelector<RootState>(
    ({ auth }) => {
      if (auth.user) {
        tokenVitality(auth.user.token)
          .then(() => {
            setIsVitality(true);
          })
          .catch(() => {
            setIsVitality(false);
          });

        return JSON.parse(JSON.stringify(auth.user)).token
      }
    }
  ) as string;


  return (
    <BrowserRouter basename={basename}>
      <ThemeProvider>
        <Switch>
          <Route path="/logout" component={Logout} />
          {!isAuthorized || !isVitality || !sessionTokenJWT ? (
            <Route>
              <PublicRoutes />
            </Route>
          ) : (
            <>
              <MasterLayout>
                <PrivateRoutes />
              </MasterLayout>
            </>
          )}
        </Switch>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export { App };
