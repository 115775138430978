/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap-v5";
import { StepperComponent } from "../../../../../_start/assets/ts/components";
import { KTSVG, formatDate } from "../../../../../_start/helpers";
import { ICreateLicData } from "../models/ILicModels";
import { deliverLicense, tokenVitality } from "../redux/LicCRUD";
import * as auth from "../../../../../app/modules/auth/redux/AuthRedux";
import { RootState } from "../../../../../setup";

type Props = {
  licData: ICreateLicData;
  show: boolean;
  handleClose: () => void;
};


const UpdateLicModal: React.FC<Props> = ({ licData, show, handleClose }) => {
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [statusComplete, setStatusComplete] = useState("");
  const [completed, setCompleted] = useState(false)
  const [isVitality, setIsVitality] = useState(true);
  const dispatch = useDispatch();

  const REACT_APP_LICENSE_FILE = process.env.REACT_APP_LICENSE_FILE || "hystoneLicense.json";



  const sessionTokenJWT = useSelector<RootState>(
    ({ auth }) => {
      if (auth.user) {
        tokenVitality(auth.user.token)
          .then(() => {
            setIsVitality(true);
          })
          .catch(() => {
            setIsVitality(false);
          });

        return JSON.parse(JSON.stringify(auth.user)).token
      }
    }
  ) as string;


  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  /*
  const updateData = (fieldsToUpdate: Partial<ICreateLicData>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(data);
  };
  */

  const checkLicenseData = (): boolean => {
    if (!licData.licenseData.organizzazione || !licData.licenseData.prodotto ||
      !licData.licenseData.email
    ) {
      return false;
    }
    return true;
  };

  const checkLicTerminiLic = (): boolean => {
    if (!licData.licenseData.terminiLicenza
      /*|| !data.licenseData.dataInizio ||
      !data.licenseData.dataFine || !data.licenseData.hostId ||
      !data.licenseData.numUtenti 
      */
    ) {
      return false;
    }
    return true;
  };

  const checkLicEstremiLic = (): boolean => {
    if (!licData.licenseData.dataInizio ||
      !licData.licenseData.dataFine ||
      !licData.licenseData.numUtenti
    ) {
      return false;
    }
    return true;
  };

  const downloadLicense = () => {
    // deliverLicense

    setLoading(true);

    setTimeout(() => {
      if (sessionTokenJWT) {
        deliverLicense(licData, sessionTokenJWT)
          .then((response) => response.data)
          .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
              new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `${REACT_APP_LICENSE_FILE}`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            //link.parentNode.removeChild(link);
            if (link != null && link.parentNode != null)
              link.parentNode.removeChild(link);

            setLoading(false);
            setCompleted(true);
            setStatusComplete("License delivered");
          })
          .catch(() => {
            setStatus("Errore generazione licenza");
            setLoading(false);
            setCompleted(true);
          });
      } else {
        dispatch(auth.actions.logout());
      }
    }, 1000);


  }


  const prevStep = () => {
    if (!isVitality) {
      dispatch(auth.actions.logout())
    }

    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();
  };

  const nextStep = () => {
    if (!isVitality) {
      dispatch(auth.actions.logout())
    }

    setHasError(false);
    if (!stepper.current) {
      return;
    }

    if (stepper.current.getCurrentStepIndex() === 1) {
      if (!checkLicenseData()) {
        setHasError(true);
        return;
      }
    } else if (stepper.current.getCurrentStepIndex() === 2) {
      if (!checkLicTerminiLic()) {
        setHasError(true);
        return;
      }
    } else if (stepper.current.getCurrentStepIndex() === 3) {
      if (!checkLicEstremiLic()) {
        setHasError(true);
        return;
      }
    }

    stepper.current.goNext();
  };

  const endProcess = () => {
    window.location.reload();
  }


  const statusMessage = () => {

    if (status) {
      return (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{status}</div>
        </div>
      )
    } else if (statusComplete) {
      return (
        <div className="mb-lg-15 alert alert-info">
          <div className="alert-text font-weight-bold">{statusComplete}</div>
        </div>
      )
    }
  }

  return (
    <>
      <Modal
        id="kt_modal_create_app"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog-centered mw-1000px h-auto"
        show={show}
        onHide={handleClose}
        onEntered={loadStepper}
      >
        <div className="container px-10 py-10">
          <div className="modal-header py-2 d-flex justify-content-end border-0">
            {/* begin::Close */}
            <div
              className="btn btn-icon btn-sm btn-light-primary"
              onClick={handleClose}
            >
              <KTSVG
                className="svg-icon-2"
                path="/media/icons/duotone/Navigation/Close.svg"
              />
            </div>
            {/* end::Close */}
          </div>

          <div className="modal-body">
            {/*begin::Stepper */}
            <div
              ref={stepperRef}
              className="stepper stepper-1 d-flex flex-column flex-xl-row flex-row-fluid"
              id="kt_modal_create_app_stepper"
            >
              {/*begin::Aside */}
              <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
                {/*begin::Nav */}
                <div className="stepper-nav d-flex flex-column pt-5">
                  {/*begin::Step 1 */}
                  <div
                    className="stepper-item current"
                    data-kt-stepper-element="nav"
                  >
                    <div className="stepper-wrapper">
                      <div className="stepper-icon">
                        <i className="stepper-check fas fa-check"></i>
                        <span className="stepper-number">1</span>
                      </div>
                      <div className="stepper-label">
                        <h3 className="stepper-title">Organization</h3>
                        <div className="stepper-desc">Organization data</div>
                      </div>
                    </div>
                  </div>
                  {/*end::Step 1 */}

                  {/*begin::Step 2 */}
                  <div className="stepper-item" data-kt-stepper-element="nav">
                    <div className="stepper-wrapper">
                      <div className="stepper-icon">
                        <i className="stepper-check fas fa-check"></i>
                        <span className="stepper-number">2</span>
                      </div>
                      <div className="stepper-label">
                        <h3 className="stepper-title">License terms</h3>
                        <div className="stepper-desc">
                          License terms
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*end::Step 2 */}

                  {/*begin::Step 3 */}
                  <div className="stepper-item" data-kt-stepper-element="nav">
                    <div className="stepper-wrapper">
                      <div className="stepper-icon">
                        <i className="stepper-check fas fa-check"></i>
                        <span className="stepper-number">3</span>
                      </div>
                      <div className="stepper-label">
                        <h3 className="stepper-title">License data</h3>
                        <div className="stepper-desc">
                          License data
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*end::Step 3 */}

                  {/*begin::Step 4 */}
                  <div className="stepper-item" data-kt-stepper-element="nav">
                    <div className="stepper-wrapper">
                      <div className="stepper-icon">
                        <i className="stepper-check fas fa-check"></i>
                        <span className="stepper-number">4</span>
                      </div>
                      <div className="stepper-label">
                        <h3 className="stepper-title">Summary</h3>
                        <div className="stepper-desc">
                          Summary
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*end::Step 4 */}


                </div>
                {/*end::Nav */}
              </div>
              {/*begin::Aside */}





              {/*begin::Content */}
              <div className="d-flex flex-row-fluid justify-content-center">
                {/*begin::Form */}
                <form
                  className="pb-5 w-100 w-md-400px w-xl-500px"
                  noValidate
                  id="kt_modal_create_app_form"
                >
                  {/*begin::Step 1 */}
                  <div className="pb-5 current" data-kt-stepper-element="content">
                    <div className="w-100">
                      {/*begin::Heading */}
                      <div className="pb-5 pb-lg-10">
                        <h3 className="fw-bolder text-dark display-6">
                          Organization
                        </h3>
                      </div>
                      {/*begin::Heading */}

                      {/*begin::Form Group */}
                      <div className="fv-row mb-12">
                        <label className="fs-6 fw-bolder text-dark form-label">
                          Organization name
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          name="organizzazione"
                          placeholder=""
                          value={licData.licenseData.organizzazione}
                          readOnly={true}
                        />

                        <label className="fs-6 fw-bolder text-dark form-label">
                          Organization E-Mail
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          name="email"
                          placeholder=""
                          value={licData.licenseData.email}
                          readOnly={true}
                        />


                        <label className="fs-6 fw-bolder text-dark form-label">
                          Product
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          name="prodotto"
                          placeholder=""
                          value={licData.licenseData.prodotto}
                          readOnly={true}
                        />

                        {!licData.licenseData.organizzazione && hasError && (
                          <div className="fv-plugins-message-container">
                            <div
                              data-field="organizzazione"
                              data-validator="notEmpty"
                              className="fv-help-block"
                            >
                              Organization name is required
                            </div>
                          </div>
                        )}
                        {!licData.licenseData.email && hasError && (
                          <div className="fv-plugins-message-container">
                            <div
                              data-field="email"
                              data-validator="notEmpty"
                              className="fv-help-block"
                            >
                              Organization E-Mail is required
                            </div>
                          </div>
                        )}
                      </div>
                      {/*end::Form Group */}
                    </div>
                  </div>
                  {/*end::Step 1 */}


                  {/*begin::Step 2 */}
                  <div className="pb-5" data-kt-stepper-element="content">
                    <div className="w-100">
                      {/*begin::Heading */}
                      <div className="pb-5 pb-lg-10">
                        <h3 className="fw-bolder text-dark display-6">
                          License terms
                        </h3>
                      </div>
                      {/*begin::Heading */}

                      {/*begin::Form Group */}
                      <div className="fv-row mb-12">
                        <label className="fs-6 fw-bolder text-dark form-label">
                          License terms
                        </label>
                        <textarea
                          rows={25}
                          className="form-control form-control-lg form-control-solid"
                          name="terminiLicenza"
                          placeholder=""
                          value={licData.licenseData.terminiLicenza}
                          readOnly={true}
                        />


                        {!licData.licenseData.terminiLicenza && hasError && (
                          <div className="fv-plugins-message-container">
                            <div
                              data-field="terminiLicenza"
                              data-validator="notEmpty"
                              className="fv-help-block"
                            >
                              License terms are required
                            </div>
                          </div>
                        )}
                      </div>
                      {/*end::Form Group */}
                    </div>
                  </div>
                  {/*end::Step 2 */}


                  {/*begin::Step 3 */}
                  <div className="pb-5" data-kt-stepper-element="content">
                    <div className="w-100">
                      {/*begin::Heading */}
                      <div className="pb-5 pb-lg-10">
                        <h3 className="fw-bolder text-dark display-6">
                          License data
                        </h3>
                      </div>
                      {/*begin::Heading */}

                      {/*begin::Form Group */}
                      <div className="fv-row mb-12">
                        <label className="fs-6 fw-bolder text-dark form-label">
                          Start date
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          name="dataInizio"
                          placeholder=""
                          value={formatDate(licData.licenseData.dataInizio)}
                          readOnly={true}
                        />

                        <label className="fs-6 fw-bolder text-dark form-label">
                          End date
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          name="dataFine"
                          placeholder=""
                          value={formatDate(licData.licenseData.dataFine)}
                          readOnly={true}
                        />

                        <label className="fs-6 fw-bolder text-dark form-label">
                          Concurrent sessions
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          name="numUtenti"
                          placeholder=""
                          value={licData.licenseData.numUtenti}
                          readOnly={true}
                        />


                        {!licData.licenseData.dataInizio && hasError && (
                          <div className="fv-plugins-message-container">
                            <div
                              data-field="dataInizio"
                              data-validator="notEmpty"
                              className="fv-help-block"
                            >
                              Start date is required
                            </div>
                          </div>
                        )}
                        {!licData.licenseData.dataFine && hasError && (
                          <div className="fv-plugins-message-container">
                            <div
                              data-field="dataFine"
                              data-validator="notEmpty"
                              className="fv-help-block"
                            >
                              End date is required
                            </div>
                          </div>
                        )}
                        {!licData.licenseData.numUtenti && hasError && (
                          <div className="fv-plugins-message-container">
                            <div
                              data-field="numUtenti"
                              data-validator="notEmpty"
                              className="fv-help-block"
                            >
                              Concurrent sessions is required
                            </div>
                          </div>
                        )}


                      </div>
                      {/*end::Form Group */}
                    </div>
                  </div>
                  {/*end::Step 3 */}

                  {/*begin::Step 4 */}
                  <div className="pb-5" data-kt-stepper-element="content">
                    <div className="w-100">
                      {/*begin::Heading */}
                      <div className="pb-5 pb-lg-10">
                        <h3 className="fw-bolder text-dark display-6">
                          License summary
                        </h3>
                      </div>
                      {/*begin::Heading */}

                      {/*begin::Form Group */}
                      <div className="fv-row mb-12">

                        <label className="fs-6 fw-bolder text-dark form-label">
                          Organization Name
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          name="organizzazione"
                          placeholder=""
                          value={licData.licenseData.organizzazione}
                          readOnly={true}
                        />

                        <label className="fs-6 fw-bolder text-dark form-label">
                          Organization E-Mail
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          name="email"
                          placeholder=""
                          value={licData.licenseData.email}
                          readOnly={true}
                        />


                        <label className="fs-6 fw-bolder text-dark form-label">
                          Product
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          name="prodotto"
                          placeholder=""
                          value={licData.licenseData.prodotto}
                          readOnly={true}
                        />

                        <label className="fs-6 fw-bolder text-dark form-label">
                          License terms
                        </label>
                        <textarea
                          rows={25}
                          className="form-control form-control-lg form-control-solid"
                          name="terminiLicenza"
                          placeholder=""
                          value={licData.licenseData.terminiLicenza}
                          readOnly={true}
                        />


                        <label className="fs-6 fw-bolder text-dark form-label">
                          Start date
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          name="dataInizio"
                          placeholder=""
                          value={formatDate(licData.licenseData.dataInizio)}
                          readOnly={true}
                        />

                        <label className="fs-6 fw-bolder text-dark form-label">
                          End date
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          name="dataFine"
                          placeholder=""
                          value={formatDate(licData.licenseData.dataFine)}
                          readOnly={true}
                        />

                        <label className="fs-6 fw-bolder text-dark form-label">
                          Concurrent sessions
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          name="numUtenti"
                          placeholder=""
                          value={licData.licenseData.numUtenti}
                          readOnly={true}
                        />
                      </div>
                      {/*end::Form Group */}

                      {statusMessage()}

                    </div>
                  </div>
                  {/*end::Step 4 */}


                  {/*begin::Actions */}
                  <div className="d-flex justify-content-between pt-10">
                    <div className="mr-2">
                      <button
                        type="button"
                        className="btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3"
                        data-kt-stepper-action="previous"
                        onClick={prevStep}
                      >
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Left-2.svg"
                          className="svg-icon-3 me-1"
                        />{" "}
                        Previous
                      </button>
                    </div>
                    <div>

                      {loading && (
                        <span
                          className="spinner-border text-primary ms-3 mt-6"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </span>
                      )}

                      {!completed ? (
                        <button
                          type="button"
                          className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3"
                          data-kt-stepper-action="submit"
                          onClick={downloadLicense}
                        >
                          Download{" "}
                          <KTSVG
                            path="/media/icons/duotone/Navigation/Right-2.svg"
                            className="svg-icon-3 ms-2"
                          />
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3"
                          onClick={endProcess}
                        >
                          End{" "}
                          <KTSVG
                            path="/media/icons/duotone/Navigation/Right-2.svg"
                            className="svg-icon-3 ms-2"
                          />
                        </button>
                      )
                      }

                      <button
                        type="button"
                        className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3"
                        data-kt-stepper-action="next"
                        onClick={nextStep}
                      >
                        Next Step{" "}
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Right-2.svg"
                          className="svg-icon-3 ms-1"
                        />
                      </button>

                    </div>
                  </div>
                  {/*end::Actions */}


                </form>
                {/*end::Form */}
              </div>
              {/*end::Content */}
            </div>
            {/* end::Stepper */}
          </div>
        </div>
      </Modal >
    </>
  );
};

export { UpdateLicModal };
