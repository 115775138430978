
export interface UserModel2 {
  user: {
    username: string,
    password: string,
    email: string,
    displayName: string,
    groups: string[],
    _id: string,
  },


}


export const defaultUserData: UserModel2 = {
  user: {
    username: "",
    password: "",
    email: "",
    displayName: "",
    groups: ["authenticated"],
    _id: "",
  },

};
