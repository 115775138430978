import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { FallbackView } from "../../_start/partials";
import { ManLicDashboardWrapper } from "../pages/dashboards/manlic-dashboard/ManLicDashboardWrapper";
import { ManUserDashboardWrapper } from "../pages/dashboards/manuser-dashboard/ManUserDashboardWrapper";

export function PrivateRoutes() {
  const DocsPageWrapper = lazy(() => import("../modules/docs/DocsPageWrapper"));

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path="/manlic" component={ManLicDashboardWrapper} />
        <Route path="/manuser" component={ManUserDashboardWrapper} />
        <Route path="/docs" component={DocsPageWrapper} />
        <Route path="/profile" component={DocsPageWrapper} />
        <Redirect from="/auth" to="/manlic" />
        <Redirect exact from="/" to="/manlic" />
        <Redirect to="manlic" />
      </Switch>
    </Suspense>
  );
}
