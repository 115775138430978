import axios from "axios";
import { UserModel } from "../models/UserModel";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:9000/api";
const AUTH_URL = process.env.REACT_APP_AUTH_URL || "http://localhost:9000/auth";


export const TOKEN_VITALITY = `${API_URL}/tokenVitality`;
export const GET_USER_BY_ACCESSTOKEN_URL = `${AUTH_URL}/get-user`;
export const LOGIN_URL = `${AUTH_URL}/login`;

export const REQUEST_PASSWORD_URL = `${AUTH_URL}/forgot-password`;
export const GET_USERS_URL = `${AUTH_URL}/getUsers`;
export const ADD_USER_URL = `${AUTH_URL}/addUser`;
export const DEL_USER_URL = `${AUTH_URL}/delUser`;

// Server should return AuthModel
export function login(username: string, password: string) {
  console.log("debug login: " + LOGIN_URL + username)
  return axios.post(LOGIN_URL, { username, password }, { withCredentials: true });
}


// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, { email });
}

export function getUsers(sessionTokenJWT: string) {
  var ret = axios.post(GET_USERS_URL,
    "sessionTokenJWT=" + sessionTokenJWT
    , { withCredentials: true }
  );

  return ret;
}

export function addUser(
  userData: {
    username: string,
    password: string,
    displayName: string,
    email: string,
    groups: string[],
  },
  sessionTokenJWT: string) {

  var ret = axios.post(ADD_USER_URL,
    "payload=" + JSON.stringify({ userData }) + "&" +
    "sessionTokenJWT=" + sessionTokenJWT
    , { withCredentials: true }
  );

  return ret;
}

export function delUser(
  userData: {
    username: string,
    password: string,
    displayName: string,
    email: string,
    groups: string[],
  },
  sessionTokenJWT: string) {

  var ret = axios.post(DEL_USER_URL,
    "payload=" + JSON.stringify({ userData }) + "&" +
    "sessionTokenJWT=" + sessionTokenJWT
    , { withCredentials: true }
  );

  return ret;
}

export function tokenVitality(sessionTokenJWT: string) {
  var ret = axios.post(TOKEN_VITALITY,
    "sessionTokenJWT=" + sessionTokenJWT);

  return ret;
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  //return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL);

  //const user: UserModel = {
  const user: UserModel = {
    id: 1,
    username: "admin",
    password: "demo",
    email: "admin@demo.com",
    auth: {
      accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
      refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
    },
    roles: [1], // Administrator
    pic: "/media/avatars/150-2.jpg",
    fullname: "Sean S",
    firstname: "Sean",
    lastname: "Stark",
    occupation: "CEO",
    companyName: "Keenthemes",
    phone: "456669067890",
    language: "en",
    timeZone: "International Date Line West",
    website: "https://keenthemes.com",
    emailSettings: {
      emailNotification: true,
      sendCopyToPersonalEmail: false,
      activityRelatesEmail: {
        youHaveNewNotifications: false,
        youAreSentADirectMessage: false,
        someoneAddsYouAsAsAConnection: true,
        uponNewOrder: false,
        newMembershipApproval: false,
        memberRegistration: true,
      },
      updatesFromKeenthemes: {
        newsAboutKeenthemesProductsAndFeatureUpdates: false,
        tipsOnGettingMoreOutOfKeen: false,
        thingsYouMissedSindeYouLastLoggedIntoKeen: true,
        newsAboutStartOnPartnerProductsAndOtherServices: true,
        tipsOnStartBusinessProducts: true,
      },
    },
    communication: {
      email: true,
      sms: true,
      phone: false,
    },
    address: {
      addressLine: "L-12-20 Vertex, Cybersquare",
      city: "San Francisco",
      state: "California",
      postCode: "45000",
    },
    socialNetworks: {
      linkedIn: "https://linkedin.com/admin",
      facebook: "https://facebook.com/admin",
      twitter: "https://twitter.com/admin",
      instagram: "https://instagram.com/admin",
    },
  };



  console.log("DEBUG getUserByToken: " + JSON.stringify(user))
  //return [200, { ...user, password: undefined }];

  return { data: user }

}
