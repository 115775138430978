import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../AssetHelpers";

type Props = {
  className?: string;
  path: string;
  svgClassName?: string;
};

const KTSVG: React.FC<Props> = ({ className = '', path, svgClassName = "mh-50px" }) => {
  return (
    <span className={`svg-icon ${className}`}>
      <SVG src={toAbsoluteUrl(path)} className={svgClassName} />
    </span>
  );
};

function formatDate(strDate: string) {
  var day = strDate.substring(0, 2);
  var month = strDate.substring(2, 4);
  var year = strDate.substring(4, 8);

  var dateObject = new Date(+year, parseInt(month) - 1, +day);

  return dateObject.toLocaleDateString([], { year: 'numeric', month: 'long', day: '2-digit' });
};

export { KTSVG, formatDate };
