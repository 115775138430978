/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { MenuItem } from "./MenuItem";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";


export function MenuInner() {

  const userType = useSelector<RootState>(
    ({ auth }) => {
      var retValue = "Customer";
      if (auth.user) {
        auth.user.user.groups.forEach(element => {
          if (element === "admin")
            retValue = "Admin";
        });
        return retValue;
      }
    }
  ) as string;

  const itemNewUser = () => {
    var myRet
    if (userType === 'Admin') {
      myRet = (<MenuItem to="/manuser" title="User management" />)
    }

    return myRet;
  }


  return (
    <>
      <div className="row">
        <div className="col-sm-4">
          <h3 className="fw-bolder mb-5">Dashboards</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              {itemNewUser()}
              <MenuItem to="/manlic" title="License management" />
              <MenuItem to="/docs" title="Documentation" />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
