import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
//import { put, takeLatest } from "redux-saga/effects";
import { UserModel2 } from "../models/UserModel2";
import { ICreateLicData } from "../models/ILicModels";


export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GenerateLicense: "[GenerateLicense] Action",
  GetLicenses: "[GetLicenses] Action"
};

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
};

export interface IAuthState {
  user?: UserModel2;
  accessToken?: string;
}

export const reducer = persistReducer(
  { storage, key: "v100-manlic-lic", whitelist: ["licenseData"] },
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.GenerateLicense: {
        const accessToken = action.payload?.accessToken;
        return { accessToken, user: undefined };
      }
      case actionTypes.GetLicenses: {
        const accessToken = action.payload?.accessToken;
        return { accessToken, user: undefined };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  generateLicense: (licenseData: ICreateLicData) =>
  (
    {
      type: actionTypes.GenerateLicense,
      payload: { licenseData }
    }),
    getLicenses: () => (
      {
        type: actionTypes.GetLicenses        
      }
    )
};

/*
export function* saga() {
  yield takeLatest(actionTypes.GenerateLicense, function* loginSaga() {
    yield put(actions.generateLicense());
  });


}
*/
