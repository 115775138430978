/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { KTSVG } from "../../../../_start/helpers";
import {
  StatsWidget6,
} from "../../../../_start/partials/widgets";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";

import { CreateUserModal } from "../_modals/create-app-stepper/CreateUserModal";
import { TablesUsersWidget } from "../_modals/create-app-stepper/TablesUsersWidget";

export const ManUserDashboardPage: React.FC = () => {
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);

  const userType = useSelector<RootState>(
    ({ auth }) => {
      var retValue = "Customer";
      if (auth.user) {
        auth.user.user.groups.forEach(element => {
          if (element === "admin")
            retValue = "Admin";
        });
        return retValue;
      }
    }
  ) as string;

  const createNewUser = () => {
    var myRet = (<></>);

    if (userType === 'Admin') {
      myRet =
        (
          <div className="row g-0 g-xl-5 g-xxl-8">
            <div className="col-xxl-4">
              <StatsWidget6 className="card-stretch mb-5  mb-xxl-8"
                bgImage="/media/misc/membership.png">
                <a
                  onClick={() => setShowCreateUserModal(true)}
                  className="btn btn-primary btn-sm fw-bolder fs-6 ps-4 mt-6"
                >
                  New user{" "}
                  <KTSVG
                    className="svg-icon-3 me-0"
                    path="/media/icons/duotone/Navigation/Up-right.svg"
                  />
                </a>
              </StatsWidget6>
            </div>
          </div>
        )
    }

    return myRet;
  }


  return (
    <>

      {createNewUser()}

      {/* begin::Row */}
      <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-lg-12">
          <  TablesUsersWidget className="card-stretch mb-5  mb-xxl-8" />
        </div>
      </div>
      {/* end::Row */}


      {/* begin::Modals */}
      <CreateUserModal
        show={showCreateUserModal}
        handleClose={() => setShowCreateUserModal(false)}
      />
      {/* end::Modals */}
    </>
  );
};
