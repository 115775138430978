/* eslint-disable jsx-a11y/anchor-is-valid */
//import React from "react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { KTSVG } from "../../../../../_start/helpers";
import { tokenVitality } from "../redux/LicCRUD";
import { getUsers } from "../../../../modules/auth/redux/AuthCRUD";
import * as auth from "../../../../modules/auth/redux/AuthRedux";
import { RootState } from "../../../../../setup";
import { DeleteUserModal } from "./DeleteUserModal";
import { defaultUserData, UserModel2 } from "../models/UserModel2";


type Props = {
  className: string;
  innerPadding?: string;
  color?: string;
};

const TablesUsersWidget: React.FC<Props> = ({
  className,
  innerPadding = "",
  color = "primary",
  children,
}) => {
  const dispatch = useDispatch();
  const [isVitality, setIsVitality] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState<UserModel2>(defaultUserData);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);


  const sessionTokenJWT = useSelector<RootState>(
    ({ auth }) => {
      if (auth.user) {
        tokenVitality(auth.user.token)
          .then(() => {
            setIsVitality(true);
          })
          .catch(() => {
            setIsVitality(false);
          });

        return JSON.parse(JSON.stringify(auth.user)).token
      }
    }
  ) as string;


  useEffect(() => {
    setTimeout(() => {
      if (sessionTokenJWT) {
        getUsers(sessionTokenJWT)
          .then(({ data: users }) => {
            //dispatch(lic.actions.getLicenses());
            setUsers(users)
            //console.log(JSON.stringify(licenses))
          })
          .catch(() => {
            console.log("debug catched")
          });
      } else {
        dispatch(auth.actions.logout());
      }
    }, 1000);

  }, [dispatch, sessionTokenJWT]);

  const handleDelUser = (e: Event) => {
    if (!isVitality) {
      dispatch(auth.actions.logout())
    }
    var myUser = {
      user: JSON.parse(JSON.stringify(e))
    }

    setSelectedUser(myUser);
    setShowDeleteUserModal(true)
  }


  const myRows = () => (
    users.map((elem, index) => {
      var jsonElem = JSON.parse(JSON.stringify(elem))
      if (jsonElem) {
        return (


          <tr key={index}>
            <td >
              <span className={`text-${color} fw-bolder d-block fs-6`} >
                {jsonElem.idUser}
              </span>
            </td>
            <td>
              <span className={`text-${color} fw-bolder d-block fs-6`}>
                {jsonElem.username}
              </span>
            </td>
            <td>
              <span className={`text-${color} fw-bolder d-block fs-6`}>
                {jsonElem.displayName}
              </span>
            </td>
            <td>
              <span className={`text-${color} fw-bolder d-block fs-6`}>
                {jsonElem.email}
              </span>
            </td>
            <td className="pe-0 text-end">
              <a
                href="#" onClick={(e) => { handleDelUser(jsonElem) }}
                className="btn btn-icon btn-bg-light  btn-color-muted btn-active-color-primary btn-sm"
              >
                <KTSVG
                  className="svg-icon-4"
                  path="/media/icons/duotone/General/Trash.svg"
                />
              </a>
            </td>

          </tr>
        )
      } else {
        return null;
      }
    })
  )


  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-dark">Gestione Utenti</span>
            <span className="text-muted mt-3 fw-bold fs-7">
              Utenti
            </span>
          </h3>
        </div>
        {/* end::Header*/}

        {/* begin::Body*/}
        <div className="card-body py-0">
          {/* begin::Table*/}
          <div className="table-responsive">
            <table
              className="table align-middle border-gray-100"
              id="kt_advance_table_widget_4"
            >
              <thead>
                <tr className="text-start text-muted fw-bolder text-gray-400 text-uppercase fs-7 border-gray-100 border-bottom-1">
                  <td className="ps-0 py-5">Identificativo</td>
                  <td className="ps-0 min-w-250px py-5">Username</td>
                  <td className="ps-0 py-5">Display name</td>
                  <td className="ps-0 py-5">E-Mail</td>
                  <td className="min-w-100px pe-0 text-end py-5">Action</td>
                </tr>
              </thead>
              <tbody>
                {myRows()}
              </tbody>
            </table>
          </div>
          {/* end::Table*/}
        </div>
        {/* end::Body*/}
      </div>

      <DeleteUserModal
        userData={selectedUser}
        show={showDeleteUserModal}
        handleClose={() => setShowDeleteUserModal(false)}
      />
    </>
  );
};

export { TablesUsersWidget };
