/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { toAbsoluteUrl } from "../../../helpers";

type Props = {
  className: string;
  bgImage: string;
  innerPadding?: string;
};

const StatsWidget6: React.FC<Props> = ({
  bgImage,
  className,
  innerPadding = "",
  children,
}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className={`card-body ${innerPadding}`}>
        {/* begin::Top */}
        <div className="d-flex bg-light-primary card-rounded flex-grow-1">
          {/* begin::Section */}
          <div className="py-10 ps-7">
            {/* begin::Text */}
            <div className="">
              {/*
              <span className="text-primary d-block mb-n1">Your Storage</span>
              <span className="font-weight-light fs-1 text-gray-800">
                GB{" "}
                <span className="fw-bolder fs-1 text-gray-800">7,562.00</span>
              </span>
              */ }
            </div>
            {/* end::Text */}
            {children}
          </div>
          {/* end::Section */}

          {/* begin::Pic */}
          <div
            className="position-relative bgi-no-repeat bgi-size-contain bgi-position-y-bottom bgi-position-x-end mt-6 flex-grow-1"
            style={{
              backgroundImage: `url('${toAbsoluteUrl(
                bgImage
              )}')`,
            }}
          ></div>
          {/* end::Pic */}
        </div>
        {/* end::Top */}

      </div>
      {/* end::Body */}
    </div>
  );
};

export { StatsWidget6 };
