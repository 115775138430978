import React from "react";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuDocs() {
  return (
    <>
      <>
        <div className="menu-item">
          <h4 className="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">
            Overview
          </h4>
        </div>
        <AsideMenuItem to="/docs/overview" title="Main features" />
        <AsideMenuItem to="/docs/results" title="Results" />
        <AsideMenuItem to="/docs/main-applications" title="Main applications" />
      </>

      <>

      </>
    </>
  );
}
