import axios from "axios";
import { ICreateLicData } from "../models/ILicModels";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:9000/api";

export const GENERATE_LICENSE_URL = `${API_URL}/generateLicense`;
export const TOKEN_VITALITY = `${API_URL}/tokenVitality`;
export const GET_LICENSES_URL = `${API_URL}/getLicenses`;
export const DELIVER_LICENSE = `${API_URL}/deliverLicense`;
export const DELETE_LICENSE = `${API_URL}/delLicense`;


export function deliverLicense(
  licData: ICreateLicData,
  sessionTokenJWT: string
) {
  return axios.post(DELIVER_LICENSE,
    "payload=" + JSON.stringify({ licData }) + "&" +
    "sessionTokenJWT=" + sessionTokenJWT
    , { withCredentials: true, responseType: 'blob' });
}

export function delLicense(
  licData: ICreateLicData,
  sessionTokenJWT: string
) {
  return axios.post(DELETE_LICENSE,
    "payload=" + JSON.stringify({ licData }) + "&" +
    "sessionTokenJWT=" + sessionTokenJWT
    , { withCredentials: true, responseType: 'blob' });
}

export function generateLicense(
  licenseData: {
    organizzazione: string,
    email: string,
    prodotto: string,
    terminiLicenza: string,
    dataInizio: string,
    dataFine: string,
    numUtenti: Number,
  },
  sessionTokenJWT: string
) {
  console.log("debug " + GENERATE_LICENSE_URL + JSON.stringify(licenseData))
  return axios.post(GENERATE_LICENSE_URL,
    "payload=" + JSON.stringify({ licenseData }) + "&" +
    "sessionTokenJWT=" + sessionTokenJWT
    , { withCredentials: true });
}

export function tokenVitality(sessionTokenJWT: string) {
  var ret = axios.post(TOKEN_VITALITY,
    "sessionTokenJWT=" + sessionTokenJWT);

  return ret;
}

export function getLicenses(sessionTokenJWT: string) {
  var ret = axios.post(GET_LICENSES_URL,
    "sessionTokenJWT=" + sessionTokenJWT
    , { withCredentials: true }
  );

  return ret;
}

