/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap-v5";
import { StepperComponent } from "../../../../../_start/assets/ts/components";
import { KTSVG, formatDate } from "../../../../../_start/helpers";
import { defaultCreateLicData, ICreateLicData } from "../models/ILicModels";
import { generateLicense, tokenVitality } from "../redux/LicCRUD";
import * as lic from "../redux/LicRedux";
import * as auth from "../../../../../app/modules/auth/redux/AuthRedux";
import { RootState } from "../../../../../setup";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import clsx from "clsx";

import "react-datepicker/dist/react-datepicker.css";
import { getAttributeValueByBreakpoint } from "../../../../../_start/assets/ts/_utils/helpers/getAttributeValueByBreakpoint";


type Props = {
  show: boolean;
  handleClose: () => void;
};

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

function dateToString(date: Date, sep: string) {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join(sep);
}


const CreateLicModal: React.FC<Props> = ({ show, handleClose }) => {
  const defaultStartDate = new Date();
  const defaultEndDate = new Date();
  defaultEndDate.setDate(defaultStartDate.getDate() + 180);

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [data, setData] = useState<ICreateLicData>(defaultCreateLicData);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [statusComplete, setStatusComplete] = useState("");
  const [completed, setCompleted] = useState(false)
  const [isVitality, setIsVitality] = useState(true);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const createlicSchema = Yup.object().shape({
    organizzazione: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Organization is required"),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    prodotto: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Product name is required"),
    versione: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .matches(
        /^(\d+\.)?(\d+\.)?(\*|\d+)$/,
        "Version number is not compliant (es 1.0)"
      )
      .required("Version number is required")
  });

  const initialValues = {
    organizzazione: "",
    email: "",
    prodotto: "HY-STONE",
    versione: "1.0",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: createlicSchema,
    onSubmit: (values) => {
      updateData({
        licenseData: {
          organizzazione: values.organizzazione,
          email: values.email,
          prodotto: values.prodotto,
          versione: values.versione,
          terminiLicenza: data.licenseData.terminiLicenza,
          dataInizio: data.licenseData.dataInizio,
          dataFine: data.licenseData.dataFine,
          numUtenti: data.licenseData.numUtenti,
        },
      })
    },
  });

  const sessionTokenJWT = useSelector<RootState>(
    ({ auth }) => {
      if (auth.user) {
        tokenVitality(auth.user.token)
          .then(() => {
            setIsVitality(true);
          })
          .catch(() => {
            setIsVitality(false);
          });

        return JSON.parse(JSON.stringify(auth.user)).token
      }
    }
  ) as string;


  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const updateData = (fieldsToUpdate: Partial<ICreateLicData>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const checkLicTerminiLic = (): boolean => {
    if (!data.licenseData.terminiLicenza
      /*|| !data.licenseData.dataInizio ||
      !data.licenseData.dataFine || !data.licenseData.hostId ||
      !data.licenseData.numUtenti 
      */
    ) {
      return false;
    }
    return true;
  };

  const checkLicEstremiLic = (): boolean => {
    if (!data.licenseData.dataInizio ||
      !data.licenseData.dataFine ||
      !data.licenseData.numUtenti
    ) {
      return false;
    }
    return true;
  };

  const prevStep = () => {
    if (!isVitality) {
      dispatch(auth.actions.logout())
    }

    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();
  };

  const nextStep = () => {
    formik.handleSubmit();

    if (!isVitality) {
      dispatch(auth.actions.logout())
    }

    setHasError(false);
    if (!stepper.current) {
      return;
    }

    if (stepper.current.getCurrentStepIndex() === 1) {

    } else if (stepper.current.getCurrentStepIndex() === 2) {
      if (!checkLicTerminiLic()) {
        setHasError(true);
        return;
      }
    } else if (stepper.current.getCurrentStepIndex() === 3) {
      data.licenseData.dataInizio = dateToString(startDate, '');
      data.licenseData.dataFine = dateToString(endDate, '');
      updateData({
        licenseData: {
          organizzazione: data.licenseData.organizzazione,
          email: data.licenseData.email,
          prodotto: data.licenseData.prodotto,
          versione: data.licenseData.versione,
          terminiLicenza: data.licenseData.terminiLicenza,
          dataInizio: data.licenseData.dataInizio,
          dataFine: data.licenseData.dataFine,
          numUtenti: data.licenseData.numUtenti,
        },
      })
      if (!checkLicEstremiLic()) {
        setHasError(true);
        return;
      }
    }

    stepper.current.goNext();
  };

  const endProcess = () => {
    window.location.reload();
  }

  const submit = () => {
    setHasError(false);
    if (!stepper.current) {
      return;
    }
    if (stepper.current.getCurrentStepIndex() === 3) {
      if (!checkLicEstremiLic()) {
        setHasError(true);
        return;
      }
    }

    setLoading(true);

    setTimeout(() => {
      if (sessionTokenJWT) {
        generateLicense(data.licenseData, sessionTokenJWT)
          //login(values.email, values.password)
          .then(({ data: licenseData }) => {
            dispatch(lic.actions.generateLicense(licenseData));
            setStatusComplete("Licenza generata con successo");
            setLoading(false);
            setCompleted(true);
          })
          .catch(() => {
            setLoading(false);
            setStatus("Errore generazione licenza");
            setCompleted(true);
          });
      } else {
        dispatch(auth.actions.logout());
      }
    }, 1000);



    // window.location.reload();
  };

  const statusMessage = () => {

    if (status) {
      return (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{status}</div>
        </div>
      )
    } else if (statusComplete) {
      return (
        <div className="mb-lg-15 alert alert-info">
          <div className="alert-text font-weight-bold">{statusComplete}</div>
        </div>
      )
    }
  }

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog-centered mw-1000px h-auto"
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
    >
      <div className="container px-10 py-10">
        <div className="modal-header py-2 d-flex justify-content-end border-0">
          {/* begin::Close */}
          <div
            className="btn btn-icon btn-sm btn-light-primary"
            onClick={handleClose}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotone/Navigation/Close.svg"
            />
          </div>
          {/* end::Close */}
        </div>

        <div className="modal-body">
          {/*begin::Stepper */}
          <div
            ref={stepperRef}
            className="stepper stepper-1 d-flex flex-column flex-xl-row flex-row-fluid"
            id="kt_modal_create_app_stepper"
          >
            {/*begin::Aside */}
            <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
              {/*begin::Nav */}
              <div className="stepper-nav d-flex flex-column pt-5">
                {/*begin::Step 1 */}
                <div
                  className="stepper-item current"
                  data-kt-stepper-element="nav"
                >
                  <div className="stepper-wrapper">
                    <div className="stepper-icon">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">1</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title">Organization</h3>
                      <div className="stepper-desc">Organization data</div>
                    </div>
                  </div>
                </div>
                {/*end::Step 1 */}

                {/*begin::Step 2 */}
                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-wrapper">
                    <div className="stepper-icon">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">2</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title">License terms</h3>
                      <div className="stepper-desc">
                        License terms
                      </div>
                    </div>
                  </div>
                </div>
                {/*end::Step 2 */}

                {/*begin::Step 3 */}
                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-wrapper">
                    <div className="stepper-icon">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">3</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title">License data</h3>
                      <div className="stepper-desc">
                        License data
                      </div>
                    </div>
                  </div>
                </div>
                {/*end::Step 3 */}

                {/*begin::Step 4 */}
                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-wrapper">
                    <div className="stepper-icon">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">4</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title">Summary</h3>
                      <div className="stepper-desc">
                        Summary
                      </div>
                    </div>
                  </div>
                </div>
                {/*end::Step 4 */}


              </div>
              {/*end::Nav */}
            </div>
            {/*begin::Aside */}





            {/*begin::Content */}
            <div className="d-flex flex-row-fluid justify-content-center">
              {/*begin::Form */}
              <form
                className="pb-5 w-100 w-md-400px w-xl-500px"
                noValidate
                id="kt_modal_create_app_form"
                onSubmit={formik.handleSubmit}
              >

                {formik.status ? (
                  <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">{formik.status}</div>
                  </div>
                ) : null
                }

                {/*begin::Step 1 */}
                <div className="pb-5 current" data-kt-stepper-element="content">
                  <div className="w-100">
                    {/*begin::Heading */}
                    <div className="pb-5 pb-lg-10">
                      <h3 className="fw-bolder text-dark display-6">
                        Organization
                      </h3>
                    </div>
                    {/*begin::Heading */}

                    {/*begin::Form Group */}
                    <div className="fv-row mb-12">
                      <label className="fs-6 fw-bolder text-dark form-label">
                        Organization name
                      </label>
                      <input
                        placeholder=""
                        {...formik.getFieldProps("organizzazione")}
                        className={clsx(
                          "form-control form-control-lg form-control-solid",
                          { "is-invalid": formik.touched.organizzazione && formik.errors.organizzazione },
                          {
                            "is-valid": formik.touched.organizzazione && !formik.errors.organizzazione,
                          }
                        )}
                        type="text"
                        name="organizzazione"
                      />
                      {formik.touched.organizzazione && formik.errors.organizzazione && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{formik.errors.organizzazione}</div>
                        </div>
                      )}

                      <label className="fs-6 fw-bolder text-dark form-label">
                        Organization E-Mail
                      </label>
                      <input
                        placeholder=""
                        {...formik.getFieldProps("email")}
                        className={clsx(
                          "form-control form-control-lg form-control-solid",
                          { "is-invalid": formik.touched.email && formik.errors.email },
                          {
                            "is-valid": formik.touched.email && !formik.errors.email,
                          }
                        )}
                        type="email"
                        name="email"
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{formik.errors.email}</div>
                        </div>
                      )}


                      <label className="fs-6 fw-bolder text-dark form-label">
                        Product
                      </label>
                      <input
                        placeholder=""
                        {...formik.getFieldProps("prodotto")}
                        className={clsx(
                          "form-control form-control-lg form-control-solid",
                          { "is-invalid": formik.touched.prodotto && formik.errors.prodotto },
                          {
                            "is-valid": formik.touched.prodotto && !formik.errors.prodotto,
                          }
                        )}
                        type="text"
                        name="prodotto"
                        readOnly={true}
                      />
                      {formik.touched.prodotto && formik.errors.prodotto && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{formik.errors.prodotto}</div>
                        </div>
                      )}

                      <label className="fs-6 fw-bolder text-dark form-label">
                        Version
                      </label>
                      <input
                        placeholder=""
                        {...formik.getFieldProps("versione")}
                        className={clsx(
                          "form-control form-control-lg form-control-solid",
                          { "is-invalid": formik.touched.versione && formik.errors.versione },
                          {
                            "is-valid": formik.touched.versione && !formik.errors.versione,
                          }
                        )}
                        type="text"
                        name="versione"
                        readOnly={false}
                      />
                      {formik.touched.versione && formik.errors.versione && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{formik.errors.versione}</div>
                        </div>
                      )}



                    </div>
                    {/*end::Form Group */}
                  </div>
                </div>
                {/*end::Step 1 */}


                {/*begin::Step 2 */}
                <div className="pb-5" data-kt-stepper-element="content">
                  <div className="w-100">
                    {/*begin::Heading */}
                    <div className="pb-5 pb-lg-10">
                      <h3 className="fw-bolder text-dark display-6">
                        License terms
                      </h3>
                    </div>
                    {/*begin::Heading */}

                    {/*begin::Form Group */}
                    <div className="fv-row mb-12">
                      <label className="fs-6 fw-bolder text-dark form-label">
                        License terms
                      </label>
                      <textarea
                        rows={25}
                        className="form-control form-control-lg form-control-solid"
                        name="terminiLicenza"
                        placeholder=""
                        value={data.licenseData.terminiLicenza}
                        onChange={(e) =>
                          updateData({
                            licenseData: {
                              organizzazione: data.licenseData.organizzazione,
                              email: data.licenseData.email,
                              prodotto: data.licenseData.prodotto,
                              versione: data.licenseData.versione,
                              terminiLicenza: e.target.value,
                              dataInizio: data.licenseData.dataInizio,
                              dataFine: data.licenseData.dataFine,
                              numUtenti: data.licenseData.numUtenti,
                            },
                          })
                        }
                      />


                      {!data.licenseData.terminiLicenza && hasError && (
                        <div className="fv-plugins-message-container">
                          <div
                            data-field="terminiLicenza"
                            data-validator="notEmpty"
                            className="fv-help-block"
                          >
                            License terms are required
                          </div>
                        </div>
                      )}
                    </div>
                    {/*end::Form Group */}
                  </div>
                </div>
                {/*end::Step 2 */}


                {/*begin::Step 3 */}
                <div className="pb-5" data-kt-stepper-element="content">
                  <div className="w-100">
                    {/*begin::Heading */}
                    <div className="pb-5 pb-lg-10">
                      <h3 className="fw-bolder text-dark display-6">
                        License data
                      </h3>
                    </div>
                    {/*begin::Heading */}

                    {/*begin::Form Group */}
                    <div className="fv-row mb-12">
                      <label className="fs-6 fw-bolder text-dark form-label">
                        Start date
                      </label>
                      <DatePicker
                        selected={startDate}
                        dateFormat="dd MMMM yyyy"
                        className="form-control form-control-lg form-control-solid"
                        onChange={(date: Date) => {
                          setStartDate(date);
                          updateData({
                            licenseData: {
                              organizzazione: data.licenseData.organizzazione,
                              email: data.licenseData.email,
                              prodotto: data.licenseData.prodotto,
                              versione: data.licenseData.versione,
                              terminiLicenza: data.licenseData.terminiLicenza,
                              dataInizio: dateToString(date, ''),
                              dataFine: data.licenseData.dataFine,
                              numUtenti: data.licenseData.numUtenti,
                            },
                          })
                        }}
                      />

                      <label className="fs-6 fw-bolder text-dark form-label">
                        End date
                      </label>
                      <DatePicker
                        selected={endDate}
                        dateFormat="dd MMMM yyyy"
                        className="form-control form-control-lg form-control-solid"
                        onChange={(date: Date) => {
                          setEndDate(date);
                          updateData({
                            licenseData: {
                              organizzazione: data.licenseData.organizzazione,
                              email: data.licenseData.email,
                              prodotto: data.licenseData.prodotto,
                              versione: data.licenseData.versione,
                              terminiLicenza: data.licenseData.terminiLicenza,
                              dataInizio: data.licenseData.dataInizio,
                              dataFine: dateToString(date, ''),
                              numUtenti: data.licenseData.numUtenti,
                            },
                          })
                        }}
                      />


                      <label className="fs-6 fw-bolder text-dark form-label">
                        Concurrent sessions
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        name="numUtenti"
                        placeholder=""
                        value={data.licenseData.numUtenti}
                        onChange={(e) => {
                          var lNumUtenti = 0;
                          if (e.target.value) {
                            lNumUtenti = parseInt(e.target.value)
                          } else {
                            lNumUtenti = 0;
                          }
                          updateData({
                            licenseData: {
                              organizzazione: data.licenseData.organizzazione,
                              email: data.licenseData.email,
                              prodotto: data.licenseData.prodotto,
                              versione: data.licenseData.versione,
                              terminiLicenza: data.licenseData.terminiLicenza,
                              dataInizio: data.licenseData.dataInizio,
                              dataFine: data.licenseData.dataFine,
                              numUtenti: lNumUtenti,
                            },
                          })
                        }
                        }
                      />


                      {!data.licenseData.dataInizio && hasError && (
                        <div className="fv-plugins-message-container">
                          <div
                            data-field="dataInizio"
                            data-validator="notEmpty"
                            className="fv-help-block"
                          >
                            Start date is required
                          </div>
                        </div>
                      )}
                      {!data.licenseData.dataFine && hasError && (
                        <div className="fv-plugins-message-container">
                          <div
                            data-field="dataFine"
                            data-validator="notEmpty"
                            className="fv-help-block"
                          >
                            End date is required
                          </div>
                        </div>
                      )}
                      {!data.licenseData.numUtenti && hasError && (
                        <div className="fv-plugins-message-container">
                          <div
                            data-field="numUtenti"
                            data-validator="notEmpty"
                            className="fv-help-block"
                          >
                            Concurrent sessions is required
                          </div>
                        </div>
                      )}


                    </div>
                    {/*end::Form Group */}
                  </div>
                </div>
                {/*end::Step 3 */}

                {/*begin::Step 4 */}
                <div className="pb-5" data-kt-stepper-element="content">
                  <div className="w-100">
                    {/*begin::Heading */}
                    <div className="pb-5 pb-lg-10">
                      <h3 className="fw-bolder text-dark display-6">
                        License summary
                      </h3>
                    </div>
                    {/*begin::Heading */}

                    {/*begin::Form Group */}
                    <div className="fv-row mb-12">

                      <label className="fs-6 fw-bolder text-dark form-label">
                        Organization Name
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        name="organizzazione"
                        placeholder=""
                        value={data.licenseData.organizzazione}
                        readOnly={true}
                        onChange={(e) =>
                          updateData({
                            licenseData: {
                              organizzazione: e.target.value,
                              email: data.licenseData.email,
                              prodotto: data.licenseData.prodotto,
                              versione: data.licenseData.versione,
                              terminiLicenza: data.licenseData.terminiLicenza,
                              dataInizio: data.licenseData.dataInizio,
                              dataFine: data.licenseData.dataFine,
                              numUtenti: data.licenseData.numUtenti,
                            },
                          })
                        }
                      />

                      <label className="fs-6 fw-bolder text-dark form-label">
                        Organization E-Mail
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        name="email"
                        placeholder=""
                        value={data.licenseData.email}
                        readOnly={true}                        
                        onChange={(e) =>
                          updateData({
                            licenseData: {
                              organizzazione: data.licenseData.organizzazione,
                              email: e.target.value,
                              prodotto: data.licenseData.prodotto,
                              versione: data.licenseData.versione,
                              terminiLicenza: data.licenseData.terminiLicenza,
                              dataInizio: data.licenseData.dataInizio,
                              dataFine: data.licenseData.dataFine,
                              numUtenti: data.licenseData.numUtenti,
                            },
                          })
                        }
                      />


                      <label className="fs-6 fw-bolder text-dark form-label">
                        Product
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        name="prodotto"
                        placeholder=""
                        value={data.licenseData.prodotto}
                        readOnly={true}                        
                        onChange={(e) =>
                          updateData({
                            licenseData: {
                              organizzazione: data.licenseData.organizzazione,
                              email: data.licenseData.email,
                              prodotto: e.target.value,
                              versione: data.licenseData.versione,
                              terminiLicenza: data.licenseData.terminiLicenza,
                              dataInizio: data.licenseData.dataInizio,
                              dataFine: data.licenseData.dataFine,
                              numUtenti: data.licenseData.numUtenti,
                            },
                          })
                        }
                      />

                      <label className="fs-6 fw-bolder text-dark form-label">
                        Version
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        name="versione"
                        placeholder=""
                        value={data.licenseData.versione}
                        readOnly={true}                        
                        onChange={(e) =>
                          updateData({
                            licenseData: {
                              organizzazione: data.licenseData.organizzazione,
                              email: data.licenseData.email,
                              prodotto: data.licenseData.prodotto,
                              versione: e.target.value,
                              terminiLicenza: data.licenseData.terminiLicenza,
                              dataInizio: data.licenseData.dataInizio,
                              dataFine: data.licenseData.dataFine,
                              numUtenti: data.licenseData.numUtenti,
                            },
                          })
                        }
                      />

                      <label className="fs-6 fw-bolder text-dark form-label">
                        License terms
                      </label>
                      <textarea
                        rows={25}
                        className="form-control form-control-lg form-control-solid"
                        name="terminiLicenza"
                        placeholder=""
                        value={data.licenseData.terminiLicenza}
                        readOnly={true}                        
                        onChange={(e) =>
                          updateData({
                            licenseData: {
                              organizzazione: data.licenseData.organizzazione,
                              email: data.licenseData.email,
                              prodotto: data.licenseData.prodotto,
                              versione: data.licenseData.versione,
                              terminiLicenza: e.target.value,
                              dataInizio: data.licenseData.dataInizio,
                              dataFine: data.licenseData.dataFine,
                              numUtenti: data.licenseData.numUtenti,
                            },
                          })
                        }
                      />


                      <label className="fs-6 fw-bolder text-dark form-label">
                        Start date
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        name="dataInizio"
                        placeholder=""
                        value={formatDate(dateToString(startDate, ''))}
                        readOnly={true}                        
                        onChange={(e) =>
                          updateData({
                            licenseData: {
                              organizzazione: data.licenseData.organizzazione,
                              email: data.licenseData.email,
                              prodotto: data.licenseData.prodotto,
                              versione: data.licenseData.versione,
                              terminiLicenza: data.licenseData.terminiLicenza,
                              dataInizio: e.target.value,
                              dataFine: data.licenseData.dataFine,
                              numUtenti: data.licenseData.numUtenti,
                            },
                          })
                        }
                      />

                      <label className="fs-6 fw-bolder text-dark form-label">
                        End date
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        name="dataFine"
                        placeholder=""
                        value={formatDate(dateToString(endDate, ''))}
                        readOnly={true}                        
                        onChange={(e) =>
                          updateData({
                            licenseData: {
                              organizzazione: data.licenseData.organizzazione,
                              email: data.licenseData.email,
                              prodotto: data.licenseData.prodotto,
                              versione: data.licenseData.versione,
                              terminiLicenza: data.licenseData.terminiLicenza,
                              dataInizio: data.licenseData.dataInizio,
                              dataFine: e.target.value,
                              numUtenti: data.licenseData.numUtenti,
                            },
                          })
                        }
                      />

                      <label className="fs-6 fw-bolder text-dark form-label">
                        Concurrent sessions
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        name="numUtenti"
                        placeholder=""
                        value={data.licenseData.numUtenti}
                        readOnly={true}                        
                        onChange={(e) =>
                          updateData({
                            licenseData: {
                              organizzazione: data.licenseData.organizzazione,
                              email: data.licenseData.email,
                              prodotto: data.licenseData.prodotto,
                              versione: data.licenseData.versione,
                              terminiLicenza: data.licenseData.terminiLicenza,
                              dataInizio: data.licenseData.dataInizio,
                              dataFine: data.licenseData.dataFine,
                              numUtenti: parseInt(e.target.value),
                            },
                          })
                        }
                      />
                    </div>
                    {/*end::Form Group */}

                    {statusMessage()}

                  </div>
                </div>
                {/*end::Step 4 */}


                {/*begin::Actions */}
                <div className="d-flex justify-content-between pt-10">
                  <div className="mr-2">
                    <button
                      type="button"
                      className="btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3"
                      data-kt-stepper-action="previous"
                      onClick={prevStep}
                    >
                      <KTSVG
                        path="/media/icons/duotone/Navigation/Left-2.svg"
                        className="svg-icon-3 me-1"
                      />{" "}
                      Previous
                    </button>
                  </div>
                  <div>
                    {loading && (
                      <span
                        className="spinner-border text-primary ms-3 mt-6"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </span>
                    )}

                    {!completed ? (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3"
                        data-kt-stepper-action="submit"
                        onClick={submit}
                      >
                        Submit{" "}
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Right-2.svg"
                          className="svg-icon-3 ms-2"
                        />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3"
                        onClick={endProcess}
                      >
                        End{" "}
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Right-2.svg"
                          className="svg-icon-3 ms-2"
                        />
                      </button>
                    )
                    }


                    <button
                      type="submit"
                      className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3"
                      data-kt-stepper-action="next"
                      onClick={nextStep}
                      disabled={!formik.isValid}
                    >
                      Next Step{" "}
                      <KTSVG
                        path="/media/icons/duotone/Navigation/Right-2.svg"
                        className="svg-icon-3 ms-1"
                      />
                    </button>

                  </div>
                </div>
                {/*end::Actions */}


              </form>
              {/*end::Form */}
            </div>
            {/*end::Content */}
          </div>
          {/* end::Stepper */}
        </div>
      </div>
    </Modal >
  );
};

export { CreateLicModal };
